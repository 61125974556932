import React from 'react'
import PropTypes from 'prop-types'
import Img from '../Img/Img'
import styles from './LinkedProject.module.scss'
import AniLink from '../AniLink/AniLink'

const colors = {
  title: {
    brown: 'xl:text-gold-100',
    gold: 'xl:text-brown-200',
    gray: 'xl:text-red-100',
    grayDark: 'xl:text-brown-200',
  },
  categoryTitle: {
    brown: 'xl:text-gold-100',
    gold: 'xl:text-brown-200',
    gray: 'xl:text-red-100',
    grayDark: 'xl:text-brown-200',
  },
  background: {
    brown: 'xl:bg-brown-300',
    gold: 'xl:bg-gold-100',
    gray: 'xl:bg-gray-200',
    grayDark: 'xl:bg-gray-300',
  },
}

const classes = {
  item: ['relative', 'mb-12', 'xl:mb-0'].join(' '),
  info: [
    'xl:absolute',
    'xl:inset-0',
    'xl:p-6',
    'xl:opacity-0',
    'xl:flex',
    'xl:items-center',
    'xl:justify-center',
  ].join(' '),
  awards: [
    'pt-1',
    'leading-none',
    'font-sans-display',
    'text-sm',
    'xl:pt-0',
    'xl:text-lg',
    '2xl:text-xl',
    'xl:font-serif-display',
    'xl:text-center',
    'xl:absolute',
    'xl:top-0',
    'xl:right-0',
    'xl:left-0',
    'xl:mt-5',
    'text-red-100',
    //
  ].join(' '),
  categoryTitle: [
    'hidden',
    'xl:block',
    'mt-2',
    'font-sans-display',
    'leading-none',
    'text-sm',
    'xl:text-base',
    'xl:text-center',
    'xl:absolute',
    'xl:right-0',
    'xl:bottom-0',
    'xl:left-0',
    // 'xl:mb-3',
    'xl:mr-6',
    'xl:mb-6',
    'xl:ml-6',
    'text-red-100',
  ].join(' '),
  title: [
    'title',
    'mt-2',
    'xl:mt-0',
    'xl:mb-6',
    '3xl:px-6',
    'font-serif-display',
    'leading-none',
    'tracking-tight',
    'uppercase',
    'xl:text-center',
    'text-2xl',
    'sm:text-3xl',
    'lg:text-4xl',
    // 'xl:text-3xl',
    '2xl:text-5xl',
    '4xl:text-6xl',
    'text-red-100',
  ].join(' '),
  background: ['absolute', 'inset-0', 'opacity-0'].join(' '),
}

const LinkedProject = ({
  title,
  categoryTitle,
  path,
  image,
  theme,
  awards,
}) => {
  return (
    <div className={[classes.item, styles.item].join(' ')}>
      <div className={styles.image}>
        <Img data={image} className='object-cover' />
      </div>
      <div className={[classes.info, styles.info].join(' ')}>
        {categoryTitle && (
          <div
            className={[
              classes.categoryTitle,
              styles.categoryTitle,
              colors.categoryTitle[theme],
            ].join(' ')}
          >
            {categoryTitle}
          </div>
        )}
        <div
          className={[
            classes.title,
            styles.title,
            colors.title[theme],
            awards ? 'xl:pt-3' : '',
          ].join(' ')}
        >
          <AniLink to={`/${path}`}>{title}</AniLink>
        </div>
        {awards && (
          <div className={[classes.awards, colors.title[theme]].join(' ')}>
            {awards}
          </div>
        )}
      </div>
      <div
        className={[
          classes.background,
          styles.background,
          colors.background[theme],
          theme === 'gray' ? styles.hasWhiteBorder : '',
        ].join(' ')}
      />
    </div>
  )
}

LinkedProject.propTypes = {
  title: PropTypes.string.isRequired,
  categoryTitle: PropTypes.string,
  path: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  theme: PropTypes.string,
  awards: PropTypes.string,
}

export default LinkedProject
