import React from 'react'
import PropTypes from 'prop-types'
import withFooterData from '../../hocs/withFooterData'

const colors = {
  brown: 'text-gold-100',
  gold: 'text-brown-200',
  gray: 'text-red-100',
  grayDark: 'text-brown-200',
}

const classes = {
  container: [
    'lg:flex',
    'right-0',
    'bottom-0',
    'left-0',
    'px-3',
    'sm:px-6',
    'pb-6',
    'z-10',
    //
  ].join(' '),
  copyright: [
    'font-sans-display',
    'leading-none',
    'text-sm',
    //
  ].join(' '),
  contact: [
    'mt-4',
    'mb-3',
    'lg:mt-0',
    'lg:mb-0',
    'lg:ml-6',
    'md:flex',
    'font-sans-display',
    'leading-none',
    'text-sm',
    //
  ].join(' '),
  contactItem: [
    'md:mr-3',
    //
  ].join(' '),
  credits: [
    'lg:ml-auto',
    'font-sans-display',
    'leading-none',
    'text-sm',
    //
  ].join(' '),
}

const Footer = ({ theme, positionStatic, removeContact, address }) => {
  const separator = <span className='hidden xl:inline md:ml-3'>|</span>
  return (
    <div
      className={[
        classes.container,
        positionStatic ? 'mt-12 sm:mt-24' : 'absolute',
      ].join(' ')}
    >
      <div className={[classes.copyright, colors[theme]].join(' ')}>
        Sandra Gross © 2019
      </div>
      {!removeContact && (
        <ul className={[classes.contact, colors[theme]].join(' ')}>
          <li className={classes.contactItem}>
            <a href='mailto:info@sandragrossfotografia.com'>
              info@sandragrossfotografia.com
              {separator}
            </a>
          </li>
          {address.map(({ text, spans }, i) => {
            let url = null
            if (spans.length > 0 && spans[0].type === 'hyperlink') {
              url = spans[0].data.url
            }

            const content = url ? <a href={url}>{text}</a> : text

            return (
              <li className={classes.contactItem} key={i}>
                {content}
                {i === 0 && separator}
              </li>
            )
          })}
        </ul>
      )}
      <a
        href='https://domo-a.com/'
        target='_blank'
        rel='noopener noreferrer'
        className={[classes.credits, colors[theme]].join(' ')}
      >
        Site by DOMO-A
      </a>
    </div>
  )
}

Footer.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.string,
  positionStatic: PropTypes.bool,
  removeContact: PropTypes.bool,
  address: PropTypes.array,
}

export default withFooterData(Footer)
