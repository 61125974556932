import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

/**
 * @param {Array} nodes
 * @param {String} lang
 */
function getCurrLangNode(nodes, currLang) {
  return nodes.find(({ lang }) => lang === currLang)
}

export default WrappedComponent => {
  const hocComponent = ({ ...props }) => {
    return (
      <StaticQuery
        query={graphql`
          query FooterQuery {
            allContact: allPrismicContact {
              nodes {
                id
                data {
                  contact {
                    raw
                  }
                  address {
                    raw
                  }
                }
                lang
              }
            }
          }
        `}
        render={data => {
          const { lang, removeContact } = props

          if (removeContact) {
            return <WrappedComponent {...props} />
          }

          const { contact, address } = getCurrLangNode(
            data.allContact.nodes,
            lang
          ).data
          return (
            <WrappedComponent
              contact={contact.raw}
              address={address.raw}
              {...props}
            />
          )
        }}
      />
    )
  }

  hocComponent.propTypes = {
    lang: PropTypes.string,
    removeContact: PropTypes.bool,
  }

  return hocComponent
}
