import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { renderToStaticMarkup } from 'react-dom/server'
import globalTranslations from '../translations/global.json'
import { setCurrentLocation } from '../actions/currentLocation'
import { setPageOverlayVisibility } from '../actions/pageOverlayVisibility'
import { slideHasDescription } from '../actions/slideHasDescription'

export default WrappedComponent => {
  class hocComponent extends Component {
    constructor(props) {
      super(props)
      const { initialize, pageContext, location, setCurrentLocation } = props
      const { lang } = pageContext
      initialize({
        languages: [
          { name: 'Català', code: 'ca' },
          { name: 'Español', code: 'es-es' },
        ],
        translation: globalTranslations,
        options: {
          defaultLanguage: lang,
          renderToStaticMarkup,
        },
      })
      setCurrentLocation(location)
    }

    componentDidMount() {
      const { hideOverlay, resetLeftScreenOffset } = this.props
      hideOverlay()
      resetLeftScreenOffset()
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  hocComponent.propTypes = {
    initialize: PropTypes.func,
    pageContext: PropTypes.object,
    hideOverlay: PropTypes.func,
    location: PropTypes.object,
    setCurrentLocation: PropTypes.func,
    resetLeftScreenOffset: PropTypes.func,
  }

  const mapDispatchToProps = dispatch => {
    return {
      hideOverlay: () => dispatch(setPageOverlayVisibility(false)),
      setCurrentLocation: location => dispatch(setCurrentLocation(location)),
      resetLeftScreenOffset: () => dispatch(slideHasDescription(false)),
    }
  }

  return withLocalize(
    connect(
      null,
      mapDispatchToProps
    )(hocComponent)
  )
}
