import React from 'react'
import PropTypes from 'prop-types'
import Img from '../Img/Img'
import styles from './StandaloneImage.module.scss'
import StandaloneImageDescription from '../StandaloneImageDescription/StandaloneImageDescription'

const classes = {
  index: [
    'mt-2',
    'font-sans-display',
    'leading-none',
    'text-red-100',
    'text-2xl',
    //
  ].join(' '),
  caption: [
    'pt-1',
    'font-sans-display',
    'leading-none',
    'text-sm',
    'text-red-100',
    //
  ].join(' '),
  description: [
    'font-sans-display',
    'text-sm',
    //
  ].join(' '),
}

const StandaloneImage = ({
  image,
  id,
  onClick,
  index,
  caption,
  descriptionButton,
  description,
  hasBorder,
}) => {
  return (
    <>
      <div
        role='button'
        className='mb-4 md:mb-0'
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick()
          }
        }}
      >
        <div
          className={[styles.image, hasBorder ? styles.hasBorder : ''].join(
            ' '
          )}
        >
          <Img data={image} id={id} />
        </div>
        {index && <div className={[classes.index].join(' ')}>{index}</div>}
        {caption && caption !== '' && (
          <div
            className={classes.caption}
            dangerouslySetInnerHTML={{ __html: caption }}
          />
        )}
      </div>
      {description && description !== '' && (
        <div className='-mt-4 pt-1 mb-4'>
          <StandaloneImageDescription
            descriptionButton={descriptionButton}
            description={description}
          />
        </div>
      )}
    </>
  )
}

StandaloneImage.propTypes = {
  image: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  index: PropTypes.number,
  caption: PropTypes.string,
  descriptionButton: PropTypes.string,
  description: PropTypes.string,
  hasBorder: PropTypes.bool,
}

export default StandaloneImage
