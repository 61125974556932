import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setShareModalVisibility } from '../../actions/shareModalVisibility'
// import ShareModalUrl from '../ShareModalUrl/ShareModalUrl'
import { Translate } from 'react-localize-redux'
import styles from './ShareModal.module.scss'

const shareButtons = [
  {
    type: 'facebook',
    network: 'Facebook',
    createHref: function(url) {
      return `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        `${url}`
      )}`
    },
  },
  {
    type: 'twitter',
    network: 'Twitter',
    createHref: function(url, title) {
      return `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
        `${title}`
      )}&url=${encodeURIComponent(`${url}`)}`
    },
  },
  {
    type: 'mail',
    network: 'Email',
    createHref: function(url, title) {
      return `mailto:?subject=${encodeURIComponent(
        `${title}`
      )}&body=${encodeURIComponent(`${title}\n\n${url}`)}`
    },
  },
]

const classes = {
  overlay: [
    'fixed',
    'inset-0',
    //
  ].join(' '),
  container: [
    'fixed',
    'right-0',
    'bottom-0',
    'left-0',
    'px-3',
    'py-8',
    'md:px-6',
    'md:py-10',
    'bg-gray-300',
    'z-10',
    //
  ].join(' '),
  title: [
    'mb-8',
    'font-serif-display',
    'leading-none',
    'text-2xl',
    'sm:text-3xl',
    'text-center',
    'text-black',
    'tracking-tight',
    // 'uppercase',
    //
  ].join(' '),
  buttons: [
    'sm:flex',
    'sm:justify-center',
    'font-sans-display',
    'font-light',
    'text-black',
    'text-center',
    // 'text-lg',
    'text-xl',
    // 'uppercase',
    //
  ].join(' '),
}

const ShareModal = ({ shareModalIsVisible, hideModal, title, url }) => {
  return (
    <>
      {shareModalIsVisible && (
        <div
          role='button'
          className={[classes.overlay, styles.overlay].join(' ')}
          onClick={() => hideModal()}
        />
      )}
      <div
        className={[
          classes.container,
          styles.container,
          shareModalIsVisible ? styles.visible : '',
        ].join(' ')}
      >
        <div className={classes.title}>
          <Translate id='share' />
        </div>
        <div className={classes.buttons}>
          {shareButtons.map(({ network, createHref }, i) => {
            return (
              <div key={i} className={i < shareButtons.length ? 'ml-3' : ''}>
                <a
                  href={createHref(url, title)}
                  className={styles.button}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <span>{network}</span>
                </a>
              </div>
            )
          })}
        </div>
        {/* <ShareModalUrl url={url} /> */}
      </div>
    </>
  )
}

ShareModal.propTypes = {
  shareModalIsVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

const mapStateToProps = ({ shareModalIsVisible }) => {
  return { shareModalIsVisible }
}

const mapDispatchToProps = dispatch => {
  return {
    hideModal: () => dispatch(setShareModalVisibility(false)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareModal)
