import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import styles from './ProjectFilters.module.scss'
import AnimateHeight from 'react-animate-height'
import { Mobile, Tablet } from '../MediaQueries/MediaQueries'

const classes = {
  mobileListContainer: [
    'sm:pt-3',
    'sm:order-3',
    'sm:w-full',
    'md:pt-0',
    'md:order-none',
    'md:w-auto',
    'md:flex',
    //
  ].join(' '),
  list: [
    'relative',
    'sm:flex',
    'sm:flex-wrap',
    'font-sans-display',
    'text-center',
    'text-lg',
    'sm:text-left',
    'sm:text-2xl',
    'lg:text-3xl',
    'xl:text-4xl',
    'text-red-100',
    'leading-loose',
    'sm:leading-normal',
  ].join(' '),
  button: [
    'relative',
    'uppercase',
    'sm:normal-case',
    'sm:font-light',
    'tracking-tight',
    'focus:outline-none',
    styles.button,
  ].join(' '),
}

const AnimatedHeightWrapper = ({ visible, children }) => {
  return (
    <>
      <Mobile>
        <AnimateHeight height={visible ? 'auto' : 0} duration={600}>
          {children}
        </AnimateHeight>
      </Mobile>
      <Tablet>{children}</Tablet>
    </>
  )
}

class ProjectFilters extends Component {
  constructor(props) {
    super(props)

    const showCategoryLinks = props.categoriesVisible
    const showResetLink = props.activeId !== null

    this.state = {
      showMobileList: false,
      showCategoryLinks,
      showResetLink,
      showProjectsLink: !showCategoryLinks,
      renderCategoryLinks: showCategoryLinks,
      renderResetLink: showResetLink,
      renderProjectsLink: !showCategoryLinks,
    }

    this.toggleMobileList = this.toggleMobileList.bind(this)
  }

  toggleMobileList() {
    this.setState({ showMobileList: !this.state.showMobileList })
  }

  componentDidUpdate(prevProps) {
    const { categoriesVisible, activeId } = this.props

    // Hide categories
    if (
      prevProps.categoriesVisible !== categoriesVisible &&
      !categoriesVisible
    ) {
      this.setState({
        showCategoryLinks: false,
        showResetLink: false,
        renderProjectsLink: true,
      })
      setTimeout(() => {
        this.setState({
          renderCategoryLinks: false,
          renderResetLink: false,
          showProjectsLink: true,
        })
      }, 600)
    }

    // Show categories
    if (
      prevProps.categoriesVisible !== categoriesVisible &&
      categoriesVisible
    ) {
      this.setState({ showProjectsLink: false })
      setTimeout(() => {
        this.setState({ renderCategoryLinks: true })
      }, 600)
      setTimeout(() => {
        this.setState({ showCategoryLinks: true, renderProjectsLink: false })
      }, 1200)
    }

    // Select category
    if (prevProps.activeId !== activeId && activeId !== null) {
      this.setState({ renderResetLink: true })
      setTimeout(() => {
        this.setState({ showResetLink: true })
      }, 1)
    }

    // Reset categories
    if (prevProps.activeId !== activeId && activeId === null) {
      this.setState({ showResetLink: false })
      setTimeout(() => {
        this.setState({ renderResetLink: false })
      }, 600)
    }
  }

  render() {
    const {
      categoriesVisible,
      categories,
      setId,
      activeId,
      mapaDomesticTitle,
      showMapaDomestic,
    } = this.props
    const {
      showMobileList,
      showCategoryLinks,
      showResetLink,
      showProjectsLink,
      renderCategoryLinks,
      renderResetLink,
      renderProjectsLink,
    } = this.state
    return (
      <div className={[classes.list, 'mt-8', 'sm:mt-12'].join(' ')}>
        <Mobile>
          <AnimateHeight height={showCategoryLinks ? 'auto' : 0} duration={600}>
            <div className={classes.list}>
              <button
                type='button'
                onClick={this.toggleMobileList}
                className={[
                  classes.button,
                  styles.mobileCategoriesButton,
                  showMobileList ? styles.active : '',
                ].join(' ')}
              >
                <span>
                  <Translate id='categories' />
                </span>
              </button>
            </div>
          </AnimateHeight>
        </Mobile>
        <div className={classes.mobileListContainer}>
          <AnimatedHeightWrapper visible={showMobileList}>
            {renderCategoryLinks &&
              categories.map(({ category }, i) => {
                const { id, document } = category
                const categoryTitle = document.data.title.text
                return (
                  <div
                    key={id}
                    className={[
                      i > 0 ? 'md:ml-5' : '',
                      styles.listItem,
                      showCategoryLinks ? styles.visible : '',
                    ].join(' ')}
                  >
                    <AnimatedHeightWrapper visible={showCategoryLinks}>
                      <button
                        type='button'
                        onClick={() => {
                          this.setState({ showMobileList: false })
                          setId(id)
                        }}
                        className={[
                          classes.button,
                          activeId === id ? styles.active : '',
                        ].join(' ')}
                      >
                        <span>{categoryTitle}</span>
                      </button>
                    </AnimatedHeightWrapper>
                  </div>
                )
              })}
            <Mobile>
              <div
                className={[
                  styles.listItem,
                  showCategoryLinks ? styles.visible : '',
                ].join(' ')}
              >
                <button
                  type='button'
                  onClick={showMapaDomestic}
                  className={[
                    classes.button,
                    !categoriesVisible ? styles.active : '',
                  ].join(' ')}
                >
                  <span>{mapaDomesticTitle}</span>
                </button>
              </div>
            </Mobile>
            {renderResetLink && (
              <div
                className={[
                  'sm:ml-5',
                  styles.listItem,
                  showResetLink ? styles.visible : '',
                ].join(' ')}
              >
                <AnimatedHeightWrapper visible={showResetLink}>
                  <button
                    type='button'
                    onClick={() => {
                      this.setState({ showMobileList: false })
                      setId(null)
                    }}
                    className={[classes.button, 'opacity-50'].join(' ')}
                  >
                    <Translate id='viewAll' />
                  </button>
                </AnimatedHeightWrapper>
              </div>
            )}
          </AnimatedHeightWrapper>
        </div>
        {renderProjectsLink && (
          <div
            className={[
              styles.listItem,
              showProjectsLink ? styles.visible : '',
            ].join(' ')}
          >
            <AnimatedHeightWrapper visible={showProjectsLink}>
              <button
                type='button'
                onClick={() => setId(null)}
                className={classes.button}
              >
                <span className='font-serif-display mr-3'>←</span>
                <Translate id='projects' />
              </button>
            </AnimatedHeightWrapper>
          </div>
        )}
        <Tablet>
          <div className='sm:ml-auto'>
            <button
              type='button'
              onClick={showMapaDomestic}
              className={[
                classes.button,
                !categoriesVisible ? styles.active : '',
              ].join(' ')}
            >
              <span>{mapaDomesticTitle}</span>
            </button>
          </div>
        </Tablet>
      </div>
    )
  }
}

ProjectFilters.propTypes = {
  categoriesVisible: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  setId: PropTypes.func.isRequired,
  activeId: PropTypes.string,
  mapaDomesticTitle: PropTypes.string.isRequired,
  showMapaDomestic: PropTypes.func.isRequired,
}

export default ProjectFilters
