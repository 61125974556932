import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import { connect } from 'react-redux'
import fitty from 'fitty'
import { PhotoSwipe } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import screenfull from 'screenfull'
import queryString from 'query-string'
import LinkedProject from '../components/LinkedProject/LinkedProject'
import LinkedProjectsGrid, {
  LinkedProjectsColumn as Col,
} from '../components/LinkedProjectsGrid/LinkedProjectsGrid'
import ProjectFilters from '../components/ProjectFilters/ProjectFilters'
import onPageRender from '../hocs/onPageRender'
import { setHeaderTheme } from '../actions/headerTheme'
import { coverThemeNames } from '../constants/coverThemeNames'
import TabPanel from '../components/TabPanel/TabPanel'
import StandaloneImage from '../components/StandaloneImage/StandaloneImage'
import Portal from '../components/Portal/Portal'
import { Translate } from 'react-localize-redux'
import Footer from '../components/Footer/Footer'
import Head from '../components/Head/Head'

/**
 * @param {Array} projects
 * @param {Array} categories
 * @param {String} activeCategoryId
 */
export function createProjectsData(projects, categories, activeCategoryId) {
  let allIds = []
  let byId = {}

  const getCategoryData = id => {
    let theme = ''
    let title = ''

    const obj = categories.find(({ category }) => {
      const { projects } = category.document.data
      return projects.find(({ project_link }) => {
        return id === project_link.document.id
      })
    })

    if (obj) {
      theme = obj.category.document.data.cover_theme
      title = obj.category.document.data.title.text
    }

    return { theme, title }
  }

  const isProjectInActiveCategory = id => {
    return categories.some(({ category }) => {
      const categoryId = category.id
      if (categoryId === activeCategoryId) {
        const { projects } = category.document.data
        return projects.find(({ project_link }) => {
          return id === project_link.document.id
        })
      } else {
        return false
      }
    })
  }

  projects.forEach(({ project }) => {
    const { document } = project
    const { id } = document
    const categoryData = getCategoryData(id)
    const categoryTitle = categoryData.title
    const theme = categoryData ? coverThemeNames[categoryData.theme] : 'gray'
    const pushToArr = function() {
      if (allIds.indexOf(id) > -1) return

      const obj = {
        ...document,
        categoryTitle,
        theme,
      }

      allIds.push(id)
      byId[id] = obj
    }

    if (activeCategoryId) {
      const isInCategory = isProjectInActiveCategory(id)
      if (isInCategory) {
        pushToArr()
      }
    } else {
      pushToArr()
    }
  })

  return { allIds, byId }
}

const classes = {
  title: [
    'font-serif-display',
    'uppercase',
    'leading-none',
    'tracking-tighter',
    'text-red-100',
  ].join(' '),
  mapaDomesticTitle: [
    'text-center',
    'font-serif-display',
    'uppercase',
    'leading-tight',
    'tracking-tight',
    'text-red-100',
    'text-3xl',
    'sm:text-4xl',
    'lg:text-6xl',
    'xl:text-7xl',
    '3xl:text-8xl',
    //
  ].join(' '),
  mapaDomesticContent: [
    'mt-6',
    'mr-auto',
    'ml-auto',
    'text-center',
    'font-serif-display',
    'leading-tight',
    'tracking-tighter',
    'text-red-100',
    'text-2xl',
    'lg:text-3xl',
    'xl:text-4xl',
    //
  ].join(' '),
  backToProjects: [
    'font-sans-display',
    'text-red-100',
    'text-lg',
    'opacity-50',
    //
  ].join(' '),
}

class ProjectsPage extends Component {
  constructor(props) {
    super(props)

    const { location } = props
    const { search } = location
    const parsedSearch = queryString.parse(search)
    const activeCategoryId = parsedSearch['category'] || null
    const showMapaDomestic = parsedSearch['mapa-domestic'] === 'true'

    this.state = {
      activeCategoryId,
      showProjects: !showMapaDomestic,
      showMapaDomestic,
      mapaDomesticPswpOpen: false,
      mapaDomesticPswpIndex: 0,
    }
    this.setCategoryId = this.setCategoryId.bind(this)
    this.showMapaDomestic = this.showMapaDomestic.bind(this)
    this.showMapaDomesticPswp = this.showMapaDomesticPswp.bind(this)
    this.hideMapaDomesticPswp = this.hideMapaDomesticPswp.bind(this)

    this.mapaDomesticPswpData = props.data.mapaDomestic.data.body.map(
      ({ primary }, index) => {
        const { image } = primary
        const { url, dimensions } = image
        const { width, height } = dimensions
        return {
          index,
          src: url,
          w: width,
          h: height,
        }
      }
    )
  }

  /**
   * @param {String} key
   * @param {any} value
   */
  setQueryParams(key, value) {
    const { location } = this.props
    const { pathname, search } = location
    const parsedSearch = queryString.parse(search)

    if (parsedSearch[key] === value) return

    if (value === null) {
      navigate(pathname)
      return
    }

    const newSearch = queryString.stringify({ [key]: value })
    const dest = `${pathname}?${newSearch}`

    navigate(dest, { replace: true })
  }

  componentDidMount() {
    const { setHeaderTheme } = this.props
    setHeaderTheme('grayWithBg')
    fitty(this.title)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.search !== this.props.location.search &&
      this.props.location.search === ''
    ) {
      this.setCategoryId(null)
    }
  }

  setCategoryId(id) {
    this.setQueryParams('category', id)

    this.setState({ showProjects: false, showMapaDomestic: false })

    setTimeout(() => {
      this.setState({ activeCategoryId: id })
    }, 700)

    setTimeout(() => {
      this.setState({ showProjects: true })
    }, 800)
  }

  showMapaDomestic() {
    this.setQueryParams('mapa-domestic', true)

    this.setState({ showProjects: false })

    setTimeout(() => {
      this.setState({ showMapaDomestic: true, activeCategoryId: null })
    }, 700)
  }

  showMapaDomesticPswp(mapaDomesticPswpIndex) {
    if (screenfull.enabled) {
      screenfull.request().then(() => {
        this.setState({ mapaDomesticPswpIndex, mapaDomesticPswpOpen: true })
      })
    }
  }

  hideMapaDomesticPswp() {
    this.setState({ mapaDomesticPswpOpen: false })
  }

  render() {
    const { data, pageContext } = this.props
    const { lang } = pageContext
    const {
      activeCategoryId,
      showMapaDomestic,
      showProjects,
      mapaDomesticPswpOpen,
      mapaDomesticPswpIndex,
    } = this.state
    const pageData = data.page.data
    const { fields } = data.page
    const { pagePath } = fields
    const { title, categories, projects } = pageData
    const mapaDomesticData = data.mapaDomestic.data
    const { allIds, byId } = createProjectsData(
      projects,
      categories,
      activeCategoryId
    )

    return (
      <>
        <Head title={pageData.title.text} path={pagePath} />
        <div className='flex flex-col min-h-screen'>
          <div>
            <div className='pt-12 sm:pt-32 px-6 mb-10 sm:mb-8'>
              <TabPanel
                visible={!showMapaDomestic}
                onAnimationStart={() => fitty(this.title)}
              >
                <div style={{ maxWidth: '800px' }}>
                  <h1 className={classes.title} ref={el => (this.title = el)}>
                    {title.text}
                  </h1>
                </div>
              </TabPanel>
              <TabPanel visible={showMapaDomestic}>
                <div>
                  <div className='hidden mb-6'>
                    <button
                      type='button'
                      onClick={() => this.setCategoryId(null)}
                      className={classes.backToProjects}
                    >
                      <Translate id='backToProjects' />
                    </button>
                  </div>
                  <div className={classes.mapaDomesticTitle}>
                    {mapaDomesticData.title.text}
                  </div>
                  <div
                    style={{ maxWidth: '500px' }}
                    className={classes.mapaDomesticContent}
                    dangerouslySetInnerHTML={{
                      __html: mapaDomesticData.description.html,
                    }}
                  />
                </div>
              </TabPanel>
              <ProjectFilters
                categoriesVisible={!showMapaDomestic}
                categories={categories}
                setId={this.setCategoryId}
                activeId={activeCategoryId}
                mapaDomesticTitle={mapaDomesticData.title.text}
                showMapaDomestic={this.showMapaDomestic}
              />
            </div>
            <TabPanel visible={showProjects}>
              <LinkedProjectsGrid disableMasonry>
                {allIds.map(id => {
                  const { data, fields, categoryTitle, theme } = byId[id]
                  const { awardsShort } = data
                  return (
                    <Col key={id}>
                      <LinkedProject
                        title={data.title.text}
                        categoryTitle={categoryTitle}
                        path={fields.pagePath}
                        image={data.cover_photo}
                        theme={theme}
                        awards={awardsShort}
                      />
                    </Col>
                  )
                })}
              </LinkedProjectsGrid>
            </TabPanel>
            <TabPanel visible={showMapaDomestic}>
              <LinkedProjectsGrid>
                {mapaDomesticData.body.map(({ id, primary }, i) => {
                  const { image } = primary
                  return (
                    <Col key={id}>
                      <StandaloneImage
                        image={image}
                        onClick={() => this.showMapaDomesticPswp(i)}
                        id={`mapaDomesticImage${i}`}
                      />
                    </Col>
                  )
                })}
              </LinkedProjectsGrid>
            </TabPanel>
          </div>
          <div className='mt-auto'>
            <Footer theme='gray' lang={lang} positionStatic />
          </div>
        </div>
        {this.mapaDomesticPswpData && this.mapaDomesticPswpData.length > 0 && (
          <Portal>
            <PhotoSwipe
              isOpen={mapaDomesticPswpOpen}
              items={this.mapaDomesticPswpData}
              onClose={this.hideMapaDomesticPswp}
              options={{
                index: mapaDomesticPswpIndex,
                showHideOpacity: true,
                history: false,
                fullscreenEl: true,
                zoomEl: false,
                shareEl: false,
                preloaderEl: false,
                barsSize: { top: 65, bottom: 60 },
                getThumbBoundsFn: index => {
                  const thumbnail = document.getElementById(
                    `mapaDomesticImage${index}`
                  )

                  if (thumbnail) {
                    const pageYScroll =
                      window.pageYOffset || document.documentElement.scrollTop
                    const rect = thumbnail.getBoundingClientRect()
                    return {
                      x: rect.left,
                      y: rect.top + pageYScroll,
                      w: rect.width,
                    }
                  }
                },
              }}
            />
          </Portal>
        )}
      </>
    )
  }
}

ProjectsPage.propTypes = {
  data: PropTypes.object.isRequired,
  setHeaderTheme: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    setHeaderTheme: theme => dispatch(setHeaderTheme(theme)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(onPageRender(ProjectsPage))

export const query = graphql`
  query($id: String!, $lang: String!) {
    page: prismicProjectsPage(id: { eq: $id }) {
      data {
        title {
          text
        }
        categories {
          ...ProjectsPageCategoryData
        }
        projects {
          ...ProjectsPageProjectData
        }
      }
      fields {
        pagePath
      }
    }
    mapaDomestic: prismicMapaDomestic(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        description {
          html
        }
        body {
          ... on PrismicMapaDomesticBodyFoto {
            id
            primary {
              image {
                ...ImageData
              }
            }
          }
        }
      }
    }
  }
`
