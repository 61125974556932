import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './TabPanel.module.scss'
import AnimateHeight from 'react-animate-height'

class TabPanel extends Component {
  constructor(props) {
    super(props)
    const { visible } = props
    this.state = {
      height: visible ? 'auto' : 0,
      showOuter: visible,
    }
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props

    if (prevProps.visible === visible) return

    if (visible) {
      this.setState({ showOuter: true })
      setTimeout(() => {
        this.setState({ height: 'auto' })
      }, 10)
    } else {
      this.setState({ height: 0 })
      setTimeout(() => {
        this.setState({ showOuter: false })
      }, 600)
    }
  }

  render() {
    const { children, onAnimationStart, onAnimationEnd } = this.props
    const { height, showOuter } = this.state
    return (
      <div className={`${styles.outer} ${showOuter ? styles.visible : ''}`}>
        <AnimateHeight
          height={height}
          animateOpacity={true}
          duration={600}
          onAnimationStart={onAnimationStart}
          onAnimationEnd={onAnimationEnd}
        >
          {children}
        </AnimateHeight>
      </div>
    )
  }
}

TabPanel.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onAnimationStart: PropTypes.func,
  onAnimationEnd: PropTypes.func,
}

export default TabPanel
