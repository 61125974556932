import React from 'react'
import PropTypes from 'prop-types'
import Masonry from 'react-masonry-css'
import styles from './LinkedProjectsGrid.module.scss'

const colsConfig = {
  default: 3,
  1280: 3,
  768: 2,
  640: 1,
}

const classes = {
  grid: ['sm:px-3'].join(' '),
  column: ['md:mb-6', 'px-3', 'sm:px-3'].join(' '),
}

const LinkedProjectsGrid = ({ children, disableMasonry }) => {
  const grid = disableMasonry ? (
    <div className={[styles.grid, styles.noMasonry].join(' ')}>{children}</div>
  ) : (
    <Masonry className={styles.grid} breakpointCols={colsConfig}>
      {children}
    </Masonry>
  )
  return <div className={classes.grid}>{grid}</div>
}

LinkedProjectsGrid.propTypes = {
  children: PropTypes.node,
  disableMasonry: PropTypes.bool,
}

export const LinkedProjectsColumn = ({ children }) => {
  return (
    <div className={[classes.column, styles.column].join(' ')}>{children}</div>
  )
}

LinkedProjectsColumn.propTypes = {
  children: PropTypes.element,
}

export default LinkedProjectsGrid
