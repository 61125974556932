import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TabPanel from '../TabPanel/TabPanel'
import styles from './StandaloneImageDescription.module.scss'
import { Translate } from 'react-localize-redux'

const classes = {
  button: [
    'relative',
    'inline-block',
    'font-sans-display',
    'focus:outline-none',
    'text-red-100',
    //
  ].join(' '),
  description: [
    'py-3',
    'font-sans-display',
    'text-red-100',
    // 'text-sm',
    //
  ].join(' '),
}

class StandaloneImageDescription extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({ visible: !this.state.visible })
  }

  render() {
    const { descriptionButton, description } = this.props
    const { visible } = this.state
    return (
      <>
        <div>
          <button
            type='button'
            onClick={this.toggle}
            className={[classes.button, styles.button].join(' ')}
          >
            <span>
              {descriptionButton ? (
                descriptionButton
              ) : (
                <Translate id='description' />
              )}
            </span>
          </button>
        </div>
        <TabPanel visible={visible}>
          <div
            className={[classes.description, styles.description].join(' ')}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </TabPanel>
      </>
    )
  }
}

StandaloneImageDescription.propTypes = {
  description: PropTypes.string.isRequired,
}

export default StandaloneImageDescription
