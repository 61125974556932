import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import ShareModal from '../ShareModal/ShareModal'
import Portal from '../Portal/Portal'

const baseUrl = 'https://sandragrossfotografia.com/'

const Head = ({ description, path, title, image }) => {
  const titleText = title ? `Sandra Gross | ${title}` : `Sandra Gross`
  let formattedDescription = null
  if (description) {
    formattedDescription = description
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/&#39;/gi, '’')

    if (formattedDescription.length > 160) {
      formattedDescription = formattedDescription.substring(0, 159) + '…'
    }
  }
  const url = `${baseUrl}${path}`

  let ogImage = null
  let ogImageWidth = null
  let ogImageHeight = null

  if (image) {
    const { w1150 } = image
    const { dimensions, url } = w1150
    const { width, height } = dimensions
    ogImage = url
    ogImageWidth = width
    ogImageHeight = height
  }

  return (
    <>
      <Helmet>
        <title>{titleText}</title>
        {formattedDescription && (
          <meta name='description' content={formattedDescription} />
        )}
        <meta name='format-detection' content='telephone=no' />
        <meta property='og:title' content={titleText} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={url} />
        {formattedDescription && (
          <meta name='og:description' content={formattedDescription} />
        )}
        {ogImage && <meta property='og:image' content={ogImage} />}
        {ogImageWidth && (
          <meta property='og:image:width' content={ogImageWidth} />
        )}
        {ogImageHeight && (
          <meta property='og:image:height' content={ogImageHeight} />
        )}
        <link
          rel='stylesheet'
          href='https://webfonts.fontstand.com/WF-045120-88f14f60d80bcf272a5084f1c10784d9.css'
          type='text/css'
        />
      </Helmet>
      <div>
        <Portal>
          <ShareModal title={titleText} url={url} />
        </Portal>
      </div>
    </>
  )
}

Head.propTypes = {
  description: PropTypes.string,
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  image: PropTypes.object,
}

export default Head
