import React from 'react'
import PropTypes from 'prop-types'
import styles from './Img.module.scss'

if (typeof window !== 'undefined') {
  require('lazysizes/plugins/attrchange/ls.attrchange.js')
  require('lazysizes/plugins/respimg/ls.respimg.js')
  require('lazysizes')
  window.lazySizes.cfg.expand = 3000
}

/**
 * @param {Object} w
 */
function srcSetFragment(w) {
  return w ? `${w.url} ${w.dimensions.width}w,` : ''
}

/**
 * @param {Object} data
 */
function createSrcSet(data) {
  let string = `
${srcSetFragment(data.w300)}
${srcSetFragment(data.w600)}
${srcSetFragment(data.w750)}
${srcSetFragment(data.w900)}
${srcSetFragment(data.w1150)}
${srcSetFragment(data.w1300)}
${srcSetFragment(data.w1600)}
${srcSetFragment(data.w1700)}
${srcSetFragment(data.w1920)}
`
  string = string.trim().replace(/(\r\n|\n|\r)/gm, '') // Remove line-breaks
  if (string[string.length - 1] === ',') {
    string = string.slice(0, string.length - 1)
  }
  return string
}

const Img = ({ data, id, className }) => {
  const { alt, url } = data
  const srcSet = createSrcSet(data)
  return (
    <div className={styles.wrap}>
      <img
        alt={alt || ''}
        src={url}
        srcSet='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
        data-sizes='auto'
        data-srcset={srcSet}
        className={['lazyload', className].join(' ')}
        id={id}
      />
    </div>
  )
}

Img.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  className: PropTypes.string,
}

Img.defaultProps = {
  className: '',
}

export default Img
